import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import './Character.css';

const Character = ({ characterSheet, isLoadingCharacter, characterError, refreshCharacterSheet, avatarUrl, characterPdfUrl, API_URL }) => {
  const [characterTabActive, setCharacterTabActive] = useState('details');
  const [parsedCharacter, setParsedCharacter] = useState(null);

  // Update parsed character whenever characterSheet changes
  useEffect(() => {
    if (characterSheet) {
      setParsedCharacter(parseCharacterSheet(characterSheet));
    } else {
      setParsedCharacter(null);
    }
  }, [characterSheet]);

  // Character sheet parsing utilities - improves data access
  const getNestedValue = (obj, path, defaultValue = 'Unknown') => {
    if (!obj) return defaultValue;
    
    // Handle both string paths ('system.abilities.str.value') and array paths (['system', 'abilities', 'str', 'value'])
    const parts = Array.isArray(path) ? path : path.split('.');
    let current = obj;
    
    for (const part of parts) {
      if (current === null || current === undefined || typeof current !== 'object') {
        return defaultValue;
      }
      current = current[part];
    }
    
    return current !== null && current !== undefined ? current : defaultValue;
  };
  
  // Parse the character sheet to get a more structured access pattern
  // This maintains compatibility with existing code but provides better alternatives
  const parseCharacterSheet = (rawData) => {
    if (!rawData) return null;
    
    const parsed = {
      raw: rawData, // Keep reference to raw data
      
      // Basic info
      name: rawData.name || 'Unnamed Character',
      level: getNestedValue(rawData, 'system.details.level', 
              getNestedValue(rawData, 'level', 0)),
              
      // Basic attributes grouped for easier access
      attributes: {
        hp: {
          value: getNestedValue(rawData, 'system.attributes.hp.value', 0),
          max: getNestedValue(rawData, 'system.attributes.hp.max', 0),
          temp: getNestedValue(rawData, 'system.attributes.hp.temp', 0)
        },
        ac: getNestedValue(rawData, 'system.attributes.ac.value', 10),
        initiative: getNestedValue(rawData, 'system.attributes.init.total', 0),
        speed: getNestedValue(rawData, 'system.attributes.movement.walk', 30),
        profBonus: getNestedValue(rawData, 'system.attributes.prof.value', 2)
      },
      
      // Movement types for easy access
      movement: {},
      
      // Abilities scores and modifiers
      abilities: {},
      
      // Grouped items by type for easier lookup
      itemsByType: {},
      
      // Key character details
      details: {
        race: null,
        class: null,
        background: null,
        alignment: getNestedValue(rawData, 'system.details.alignment', 'Unknown')
      },
      
      // Character currency
      currency: {
        pp: getNestedValue(rawData, 'system.currency.pp', 0),
        gp: getNestedValue(rawData, 'system.currency.gp', 0),
        ep: getNestedValue(rawData, 'system.currency.ep', 0),
        sp: getNestedValue(rawData, 'system.currency.sp', 0),
        cp: getNestedValue(rawData, 'system.currency.cp', 0)
      },
      
      // Biography data
      biography: {}
    };
    
    // Process movement types from the character sheet
    const movement = getNestedValue(rawData, 'system.attributes.movement', {});
    if (typeof movement === 'object') {
      Object.entries(movement).forEach(([key, value]) => {
        parsed.movement[key] = value;
      });
    }
    
    // Process ability scores
    const abilities = getNestedValue(rawData, 'system.abilities', {});
    if (typeof abilities === 'object') {
      Object.entries(abilities).forEach(([key, ability]) => {
        const value = ability?.value || 10;
        const mod = Math.floor((value - 10) / 2);
        parsed.abilities[key] = {
          value: value,
          mod: mod,
          proficient: ability?.proficient || false
        };
      });
    }
    
    // Group items by type for easier lookup
    if (Array.isArray(rawData.items)) {
      rawData.items.forEach(item => {
        const type = item.type || 'unknown';
        if (!parsed.itemsByType[type]) {
          parsed.itemsByType[type] = [];
        }
        parsed.itemsByType[type].push(item);
      });
      
      // Find character class (prioritize class item over system data)
      const classItems = rawData.items.filter(item => 
        item.type === 'class' && item.name && item.name.trim() !== ''
      );
      if (classItems.length > 0) {
        // Join multiple class names if there are several
        parsed.details.class = classItems.map(item => item.name).join(' / ');
      } else {
        parsed.details.class = getNestedValue(rawData, 'system.details.class', 
                             getNestedValue(rawData, 'system.details.originalClass', 'Unknown'));
      }
      
      // Find character race (prioritize race item over system data)
      const raceItem = rawData.items.find(item => 
        item.type === 'race' || item.type === 'ancestry' || item.type === 'heritage'
      );
      if (raceItem && raceItem.name) {
        parsed.details.race = raceItem.name;
      } else {
        parsed.details.race = getNestedValue(rawData, 'system.details.race', 
                            getNestedValue(rawData, 'system.traits.race', 'Unknown'));
      }
      
      // Find character background
      const bgItem = rawData.items.find(item => item.type === 'background');
      if (bgItem && bgItem.name) {
        parsed.details.background = bgItem.name;
      } else {
        parsed.details.background = getNestedValue(rawData, 'system.details.background', 'Unknown');
      }
    }
    
    // Process biography data from multiple potential locations
    let bio = null;
    if (rawData.system?.details?.biography) {
      bio = rawData.system.details.biography;
    } else if (rawData.system?.description) {
      bio = rawData.system.description;
    } else if (rawData.system?.details?.description) {
      bio = rawData.system.details.description;
    } else if (rawData.description) {
      bio = rawData.description;
    }
    
    if (bio) {
      // Properly format biography fields
      parsed.biography = {
        full: bio.value || bio.text || bio.public || '',
        appearance: bio.appearance || bio.physical || '',
        traits: bio.trait || bio.traits || bio.personality || '',
        ideals: bio.ideal || bio.ideals || '',
        bonds: bio.bond || bio.bonds || '',
        flaws: bio.flaw || bio.flaws || '',
        backstory: bio.backstory || bio.background || ''
      };
    }
    
    return parsed;
  };

  const handleCharacterTabClick = (tab) => {
    setCharacterTabActive(tab);
  };

  // Calculate ability modifier
  const getAbilityModifier = (score) => {
    if (!score) return 0;
    return Math.floor((score - 10) / 2);
  };

  // Render HTML content safely using an iframe to isolate the content
  const createIframeSrc = (content) => {
    const htmlContent = `
<!DOCTYPE html>
<html>
<head>
  <meta charset="UTF-8">
  <style>
    body {
      margin: 0;
      padding: 0 0 40px 0;
      font-family: 'Crimson Text', serif;
      color: #e2e8f0;
      background-color: transparent;
      font-size: 16px;
      line-height: 1.5;
    }
    
    /* Only paragraphs of content should be visible */
    p {
      margin-bottom: 16px;
    }
    
    /* Always ensure links are visible */
    a { color: #ffd700; text-decoration: underline; }
  </style>
</head>
<body>${content || ''}</body>
</html>`;
    
    return `data:text/html;charset=utf-8,${encodeURIComponent(htmlContent)}`;
  };

  // Backwards compatibility wrapper for the old renderHtml function
  const renderHtml = (html) => {
    return { __html: html };
  };

  // Helper for special value display
  const getDisplayValue = (key, value) => {
    // If value doesn't exist, return Unknown
    if (value === null || value === undefined || value === '') return 'Unknown';
    
    // Special handling for specific keys
    if (key === 'class') {
      // First look for class items which are more reliable
      if (characterSheet.items && Array.isArray(characterSheet.items)) {
        const classItems = characterSheet.items.filter(item => 
          item.type === 'class' && item.name && item.name.trim() !== ''
        );
        if (classItems.length > 0) {
          // Join multiple class names if there are several
          return classItems.map(item => item.name).join(' / ');
        }
      }
    }
    
    if (key === 'race') {
      // Check if we have a race item
      if (characterSheet.items && Array.isArray(characterSheet.items)) {
        const raceItem = characterSheet.items.find(item => 
          item.type === 'race' || item.type === 'ancestry' || item.type === 'heritage'
        );
        if (raceItem && raceItem.name) return raceItem.name;
      }
      
      // Otherwise try to extract from various locations
      if (characterSheet.system && characterSheet.system.traits && characterSheet.system.traits.race) {
        return characterSheet.system.traits.race;
      }
    }
    
    if (key === 'background') {
      // Check if we have a background item
      if (characterSheet.items && Array.isArray(characterSheet.items)) {
        const bgItem = characterSheet.items.find(item => item.type === 'background');
        if (bgItem && bgItem.name) return bgItem.name;
      }
    }
    
    if (key === 'alignment') {
      // Common alignment codes
      const alignments = {
        'lg': 'Lawful Good',
        'ng': 'Neutral Good',
        'cg': 'Chaotic Good',
        'ln': 'Lawful Neutral',
        'n': 'Neutral',
        'cn': 'Chaotic Neutral',
        'le': 'Lawful Evil',
        'ne': 'Neutral Evil',
        'ce': 'Chaotic Evil'
      };
      
      // Check if this is a known alignment code
      if (typeof value === 'string' && alignments[value.toLowerCase()]) {
        return alignments[value.toLowerCase()];
      }
    }
    
    if (key === 'originalClass') {
      // Check for class item
      if (characterSheet.items && Array.isArray(characterSheet.items)) {
        const classItem = characterSheet.items.find(item => 
          item.type === 'class' && item.name && item.name.trim() !== ''
        );
        if (classItem) return classItem.name;
      }
    }
    
    // If value is a simple string that's not an ID (no periods or @), return it
    if (typeof value === 'string') {
      // Clean up compendium references
      if (value.includes('@Compendium')) {
        const match = value.match(/\[([^\]]+)\]/);
        if (match && match[1]) {
          const parts = match[1].split('.');
          if (parts.length > 0) {
            // Get the last part and clean it
            const name = parts[parts.length - 1]
              .replace(/[0-9]+$/, '') // Remove trailing numbers
              .replace(/([A-Z])/g, ' $1') // Add spaces before capital letters
              .replace(/^[^a-zA-Z]+/, '') // Remove leading non-alphabetic chars
              .trim();
              
            if (name) return name.charAt(0).toUpperCase() + name.slice(1);
          }
        }
      }
      
      // For dnd5e.X.Y style IDs (e.g., dnd5e.races.elf)
      if (value.includes('.')) {
        const parts = value.split('.');
        if (parts.length > 0) {
          return parts[parts.length - 1]
            .split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
        }
      }
      
      // For unrecognized strings that look like IDs
      if (/^[A-Za-z0-9]{10,}$/.test(value)) {
        // This looks like an ID - try to find the item
        if (characterSheet.items && Array.isArray(characterSheet.items)) {
          // Look for an item with this ID or name containing this
          const item = characterSheet.items.find(item => 
            item._id === value || 
            (item.name && item.name.includes(value))
          );
          if (item && item.name) return item.name;
        }
        
        // If we can't find an item, this might be a code - return Unknown
        return 'Unknown';
      }
      
      // For regular strings, just return as is
      return value;
    }
    
    // For numeric values
    if (typeof value === 'number') {
      return value.toString();
    }
    
    // For anything else, return string representation
    return String(value);
  };

  // Clean text from Foundry VTT artifacts
  const cleanText = (text, preventRecursion = false) => {
    if (!text) return '';
    
    // Handle direct object references before any string conversion
    if (typeof text !== 'string') {
      if (text && typeof text === 'object') {
        try {
          // Try to extract a name property if it exists
          if (text.name) return text.name;
          // Otherwise try to get a meaningful JSON representation
          const json = JSON.stringify(text);
          if (json === '{}') return '';
          if (json === '[]') return '';
          return json;
        } catch (e) {
          return '';
        }
      }
      text = String(text);
    }
    
    // Special formatting for class descriptions - only if we're not in a recursive call
    if (!preventRecursion) {
      // DIRECT REMOVAL of Sorcerer large text block
      if (text.includes("Sorcerer") && text.includes("Evyn Fong") && text.length > 300) {
        // Just return an empty string to completely remove this text block
        return '';
      }

      // For Wild Magic Sorcery subclass text block
      if (text.includes("Wild Magic Sorcery") && 
          text.includes("Ekaterina Burmark") && 
          text.includes("Unleash Chaotic Magic") &&
          text.length > 300) {
        return '';
      }

      // For Monk class large text starting with "Jenna Raghavi Monks use rigorous combat training"
      if (text.includes("Jenna Raghavi Monks use rigorous combat training") && 
          text.includes("Focus Points") && 
          text.length > 300) {
        return '';
      }
      
      // For Fighter class large text with class table
      if (text.includes("Fighters rule many battlefields") && 
          text.includes("Weapon Mastery") &&
          text.includes("Ability Score Improvement") &&
          text.length > 300) {
        return '';
      }
      
      // For Battle Master class with exact match on name and content
      if ((text.includes("Battle Master") && text.includes("Ignarius Budi")) && 
          text.includes("Combat Superiority") &&
          text.includes("Superiority Dice") &&
          text.length > 200) {
        return '';
      }
      
      // For Druid class large text with class table
      if ((text.includes("Druid") && text.includes("Alexander Mokhov")) || 
          (text.includes("Druids") && 
          text.includes("Wild Shape") && 
          text.includes("Circle") &&
          text.length > 300)) {
        return '';
      }
      
      // For Metamagic sections - KEEP THESE
      if (text.toUpperCase().includes('METAMAGIC') && text.length > 50) {
        return formatMetamagicSection(text);
      }
    }
    
    // Test the text for specific known problematic features from the screenshots
    
    // For Monk Martial Arts
    if (text.includes('@UUID') && (text.includes('Bonus Unarmed Strike') || text.includes('Martial Arts Die') || text.includes('Dexterous Attacks'))) {
      return 'Martial Arts: Bonus Unarmed Strike, Martial Arts Die, Dexterous Attacks';
    }
    
    // For Breath Attacks
    if (text.includes('BREATH OF THE DRAGON') && text.includes('OBJECT') || 
        text.includes('@UUID') && text.includes('BREATH OF THE DRAGON')) {
      return 'Breath of the Dragon: When you take the Attack action on your turn, you can replace one of the attacks with an exhalation of draconic energy in either a 20-foot cone or a 30-foot line that is 5 feet wide. Each creature in that area must make a Dexterity saving throw against your ki save DC, taking damage of the chosen type equal to two rolls of your Martial Arts die on a failed save, or half as much damage on a successful one.';
    }
    
    // For Deflect Attacks
    if (text.includes('DEFLECT ATTACKS') && text.includes('OBJECT') || 
        text.includes('@UUID') && text.includes('DEFLECT ATTACKS')) {
      return 'Deflect Attacks: When an attack roll hits you and the damage includes bludgeoning, piercing, or slashing damage, you can take a Reaction to reduce the attack\'s total damage against you. The reduction equals 1d10 plus your Dexterity modifier and Monk level.';
    }
    
    // For Breath Weapon
    if (text.includes('BREATH WEAPON') && text.includes('OBJECT') || 
        text.includes('@UUID') && text.includes('BREATH WEAPON')) {
      return 'Breath Weapon: When you take the Attack action on your turn, you can replace one of your attacks with an exhalation of magical energy in a 15-foot cone. Each creature in that area must make a Dexterity saving throw (DC = 8 + your Constitution modifier + your proficiency bonus). On a failed save, the creature takes 1d10 type-specific damage. On a successful save, it takes half as much damage. This damage increases by 1d10 when you reach 5th level (2d10), 11th level (3d10), and 17th level (4d10).';
    }
    
    // Handle lines with multiple UUID references (like in Martial Arts)
    if (text.includes('@UUID') && text.split('@UUID').length > 2) {
      // Extract all the feature names from the UUID references
      const featureNames = [];
      let match;
      const uuidRegex = /@UUID\[.+?\]{(.+?)}/g;
      while ((match = uuidRegex.exec(text)) !== null) {
        featureNames.push(match[1]);
      }
      
      if (featureNames.length > 0) {
        // Try to extract the parent feature name
        const parentFeature = text.substring(0, text.indexOf('@UUID')).trim();
        if (parentFeature) {
          return `${parentFeature}: ${featureNames.join(', ')}`;
        } else {
          return featureNames.join(', ');
        }
      }
    }
    
    // Specific manual fixes for common problematic titles
    if (text.toUpperCase().includes('FONT OF MAGIC') && text.toUpperCase().includes('OBJECT')) {
      // Direct fix for Font of Magic
      const fontOfMagicText = `Font of Magic
You can tap into the wellspring of magic within yourself. This wellspring is represented by Sorcery Points, which allow you to create a variety of magical effects. You have 2 Sorcery Points, and you gain more as you reach higher levels, as shown in the Sorcery Points column of the Sorcerer Features table. You can't have more Sorcery Points than the number shown in the table for your level. You regain all expended Sorcery Points when you finish a Long Rest. You can use your Sorcery Points to fuel the options below, along with other features, such as Metamagic, that use those points.

Converting Spell Slots to Sorcery Points. You can expend a spell slot to gain a number of Sorcery Points equal to the slot's level (no action required).

Creating Spell Slots. As a Bonus Action, you can transform unexpended Sorcery Points into one spell slot. The Creating Spell Slots table shows the cost of creating a spell slot of a given level, and it lists the minimum Sorcerer level you must be to create a slot. You can create a spell slot no higher than level 5. Any spell slot you create with this feature vanishes when you finish a Long Rest.

Creating Spell Slots
Spell Slot Level | Sorcery Point Cost | Min. Sorcerer Level
1 | 2 | 2
2 | 3 | 3
3 | 5 | 5
4 | 6 | 7
5 | 7 | 9

Any spell slot you create with this feature vanishes when you finish a Long Rest.`;
      return fontOfMagicText;
    }
    
    if (text.toUpperCase().includes('WILD MAGIC SURGE') && text.toUpperCase().includes('OBJECT')) {
      // Direct fix for Wild Magic Surge
      return text.replace(/WILD MAGIC SURGE[\s\S]*?\([Oo][Bb][Jj][Ee][Cc][Tt][\s\S]*?\)/, 'WILD MAGIC SURGE');
    }
    
    if (text.toUpperCase().includes('INNATE SORCERY') && text.toUpperCase().includes('OBJECT')) {
      // Direct fix for Innate Sorcery
      return text.replace(/INNATE SORCERY[\s\S]*?\([Oo][Bb][Jj][Ee][Cc][Tt][\s\S]*?\)/, 'INNATE SORCERY');
    }
    
    if (text.toUpperCase().includes('KI SENSITIVITY') && text.toUpperCase().includes('OBJECT')) {
      // Direct fix for Ki Sensitivity
      return text.replace(/KI SENSITIVITY[\s\S]*?\([Oo][Bb][Jj][Ee][Cc][Tt][\s\S]*?\)/, 'KI SENSITIVITY');
    }
    
    if (text.toUpperCase().includes('LUCKY') && text.toUpperCase().includes('OBJECT')) {
      // Direct fix for Lucky
      return text.replace(/LUCKY[\s\S]*?\([Oo][Bb][Jj][Ee][Cc][Tt][\s\S]*?\)/, 'LUCKY');
    }
    
    // Clean up alert feat 
    if (text.toUpperCase().includes('ALERT') && text.toUpperCase().includes('OBJECT')) {
      return 'Alert: You gain the following benefits:\n- Initiative Proficiency: When you roll Initiative, you can add your Proficiency bonus to the roll.\n- Initiative Swap: Immediately after you roll Initiative, you can swap your Initiative with the Initiative of one willing ally in the same combat. You can\'t make this swap if you or the ally has the Incapacitated condition.';
    }
    
    // Gem Flight
    if (text.toUpperCase().includes('GEM FLIGHT') && text.toUpperCase().includes('OBJECT')) {
      return 'Gem Flight: Starting at 5th level, you can use a bonus action to manifest spectral wings on your body. These wings last for 1 minute. For the duration, you gain a flying speed equal to your walking speed and can hover. Once you use this trait, you can\'t do so again until you finish a long rest.';
    }
    
    let cleanedText = text;
    
    // Super aggressive replacement for all OBJECT OBJECT variations with case-insensitive matching
    cleanedText = cleanedText.replace(/\[?[Oo][Bb][Jj][Ee][Cc][Tt]\s*[Oo][Bb][Jj][Ee][Cc][Tt]\]?/g, '');
    cleanedText = cleanedText.replace(/\([Oo][Bb][Jj][Ee][Cc][Tt]\s*[Oo][Bb][Jj][Ee][Cc][Tt]\)/g, '');
    cleanedText = cleanedText.replace(/\[\s*[Oo][Bb][Jj][Ee][Cc][Tt]\s*[Oo][Bb][Jj][Ee][Cc][Tt]\s*\]/g, '');
    
    // Handle UUID references which are common in Foundry VTT (in order of specificity)
    cleanedText = cleanedText.replace(/@UUID\[Actor\.[^.]+?\.Item\.[^.]+?\]{(.+?)}/g, '$1');
    cleanedText = cleanedText.replace(/@UUID\[.+?\.Item\.([^.]+?)\]{(.+?)}/g, '$2');
    cleanedText = cleanedText.replace(/@UUID\[.+?\.?[^\.]+?\]{(.+?)}/g, '$1');
    
    // Handle common Foundry VTT tag formats with more specific patterns
    cleanedText = cleanedText.replace(/\[\[\/([a-z]+) +(.+?)\]\]/g, '$2');
    cleanedText = cleanedText.replace(/\{@([a-z]+) (.+?)(\|.+?)?\}/g, '$2');
    
    // Remove HTML tags but preserve content
    cleanedText = cleanedText.replace(/<\/?[^>]+(>|$)/g, ' ');
    
    // Remove weird Foundry brackets if they don't contain important content
    cleanedText = cleanedText.replace(/\[\[\s*(.+?)\s*\]\]/g, '$1');
    
    // Clean up repeated brackets from Foundry VTT
    cleanedText = cleanedText.replace(/{{(.+?)}}/g, '$1');
    
    // Handle @tag patterns from Foundry
    cleanedText = cleanedText.replace(/@([a-z]+)\[([^\|]+)\|?([^\]]*)\]/g, '$2');
    
    // Replace unnecessary object names often found in Foundry exports
    cleanedText = cleanedText.replace(/([A-Z\s]+)\s*\(.*?[Oo][Bb][Jj][Ee][Cc][Tt].*?\)/g, '$1');
    
    // Replace Foundry roll patterns: [[/r 1d20]] or [[/damage 1d10]] with just the dice notation
    cleanedText = cleanedText.replace(/\[\[\/(?:r|roll|damage|heal|check)\s+([^\]]+)\]\]/g, '$1');
    
    // Replace @variantrule references with just the rule name
    cleanedText = cleanedText.replace(/@variantrule\[([^\|]+)\|[^\]]+\]/g, '$1');
    
    // Replace @item references with just the item name
    cleanedText = cleanedText.replace(/@item\[([^\|]+)\|[^\]]+\]/g, '$1');
    
    // Replace @spell references with just the spell name
    cleanedText = cleanedText.replace(/@spell\[([^\|]+)\|[^\]]+\]/g, '$1');
    
    // Replace @class references with just the class name
    cleanedText = cleanedText.replace(/@class\[([^\|]+)\|[^\]]+\]/g, '$1');
    
    // Replace @check references with just the check name
    cleanedText = cleanedText.replace(/@check\[([^\|]+)\|[^\]]+\]/g, '$1 check');
    
    // Handle Actor and Compendium references
    cleanedText = cleanedText.replace(/@Actor\[.+?\]{(.+?)}/g, '$1');
    cleanedText = cleanedText.replace(/@Compendium\[.+?\]{(.+?)}/g, '$1');
    
    // Handle inline roll notation that might remain
    cleanedText = cleanedText.replace(/\[\[(.*?)\]\]/g, '$1');
    
    // Handle line breaks for better formatting
    cleanedText = cleanedText.replace(/\\n/g, '\n');
    
    // Remove any leftover parentheses that might contain nothing after our replacements
    cleanedText = cleanedText.replace(/\(\s*\)/g, '');
    cleanedText = cleanedText.replace(/\[\s*\]/g, '');
    
    // Clean up multiple newlines without removing them completely
    cleanedText = cleanedText.replace(/\n{3,}/g, '\n\n');
    
    // Clean up extra spaces without removing too much
    cleanedText = cleanedText.replace(/\s{2,}/g, ' ').trim();
    
    return cleanedText;
  };
  
  // Format the Sorcerer class description with better paragraph breaks
  const formatSorcererClass = (text) => {
    let cleanedText = text;
    
    // Clean up common Foundry artifacts first
    cleanedText = cleanText(cleanedText, true);
    
    // Add paragraphs for sorcerer-specific content based on keywords
    let paragraphsToFormat = [
      { startsWith: "When you cast a spell", preText: "\n\n" },
      { startsWith: "METAMAGIC", preText: "\n\n" },
      { startsWith: "Metamagic", preText: "\n\n" },
      { startsWith: "SUBTLE SPELL", preText: "\n\n" },
      { startsWith: "Subtle Spell", preText: "\n\n" },
      { startsWith: "QUICKENED SPELL", preText: "\n\n" },
      { startsWith: "Quickened Spell", preText: "\n\n" },
      { startsWith: "Sorcerer Points", preText: "\n\n" },
      { startsWith: "SORCERY POINTS", preText: "\n\n" },
      { startsWith: "Sorcery Points", preText: "\n\n" },
      { startsWith: "You can spend", preText: "\n\n" },
      { startsWith: "Your innate magic", preText: "\n\n" },
      { startsWith: "Spellcasting", preText: "\n\n" }
    ];

    // Apply formatting
    paragraphsToFormat.forEach(({ startsWith, preText }) => {
      const regex = new RegExp(`(${startsWith}[^.]*)`, 'gi');
      cleanedText = cleanedText.replace(regex, `${preText}$1`);
    });
    
    // Format bullet points for abilities
    cleanedText = cleanedText.replace(/(\d+(?:st|nd|rd|th) \+\d+ [A-Za-z ]+ \d+(?:\.\d+)?)/g, '\n• $1');
    
    // Format level-specific features
    cleanedText = cleanedText.replace(/(\d+(?:st|nd|rd|th)[^.]* — [^.]*\.)/g, '\n\n$1');
    
    // Clean up multiple newlines without removing them completely
    cleanedText = cleanedText.replace(/\n{3,}/g, '\n\n');
    
    return cleanedText;
  };
  
  // Format the Monk class description with better paragraph breaks
  const formatMonkClass = (text) => {
    let cleanedText = text;
    
    // Clean up common Foundry artifacts first
    cleanedText = cleanText(cleanedText, true);
    
    // Add paragraphs for monk-specific content based on keywords
    let paragraphsToFormat = [
      { startsWith: "MONK", preText: "\n\n" },
      { startsWith: "Monk", preText: "\n\n" },
      { startsWith: "MARTIAL ARTS", preText: "\n\n" },
      { startsWith: "Martial Arts", preText: "\n\n" },
      { startsWith: "MONK'S FOCUS", preText: "\n\n" },
      { startsWith: "Monk's Focus", preText: "\n\n" },
      { startsWith: "Your focus and martial training", preText: "\n\n" },
      { startsWith: "You can expend", preText: "\n\n" },
      { startsWith: "STEP OF THE WIND", preText: "\n\n" },
      { startsWith: "Step of the Wind", preText: "\n\n" },
      { startsWith: "PATIENT DEFENSE", preText: "\n\n" },
      { startsWith: "Patient Defense", preText: "\n\n" },
      { startsWith: "KI", preText: "\n\n" },
      { startsWith: "FLURRY OF BLOWS", preText: "\n\n" },
      { startsWith: "Flurry of Blows", preText: "\n\n" },
      { startsWith: "LANGUAGES", preText: "\n\n" },
      { startsWith: "You can speak", preText: "\n\n" }
    ];

    // Apply formatting
    paragraphsToFormat.forEach(({ startsWith, preText }) => {
      const regex = new RegExp(`(${startsWith}[^.]*)`, 'gi');
      cleanedText = cleanedText.replace(regex, `${preText}$1`);
    });
    
    // Format bullet points for monk abilities
    cleanedText = cleanedText.replace(/(\w+? Moves?: [^.]+\.)/g, '\n• $1');
    
    // Format ability features
    cleanedText = cleanedText.replace(/((?:Unarmored|Heightened|Disciplined|Evasion) [^:]+:)/g, '\n\n$1');
    
    // Clean up multiple newlines without removing them completely
    cleanedText = cleanedText.replace(/\n{3,}/g, '\n\n');
    
    return cleanedText;
  };
  
  // Format metamagic section
  const formatMetamagicSection = (text) => {
    let cleanedText = text;
    
    // Clean up common Foundry artifacts first
    cleanedText = cleanText(cleanedText, true);
    
    // Format the title
    if (cleanedText.toUpperCase().includes('METAMAGIC')) {
      cleanedText = cleanedText.replace(/(METAMAGIC:|Metamagic:)/i, 'Metamagic:\n');
    }
    
    // Format individual metamagic options
    cleanedText = cleanedText.replace(/(Subtle Spell|Quickened Spell|Twinned Spell|Heightened Spell|Careful Spell|Empowered Spell|Extended Spell):/gi, '\n\n$1:');
    
    // Format metamagic rules
    cleanedText = cleanedText.replace(/(When you cast a spell[^.]*\.)/g, '\n\n$1');
    
    // Clean up multiple newlines
    cleanedText = cleanedText.replace(/\n{3,}/g, '\n\n');
    
    return cleanedText;
  };

  // Tab render functions
  const renderDetailsTab = () => {
    if (!characterSheet) return '';
    let html = '';
    
    // Character details section
    html += '<div class="character-details-section">';
    html += '<h3>CHARACTER DETAILS</h3>';
    
    // Create a grid layout for character details
    html += '<div class="character-details-grid">';
    
    // Create individual detail boxes with labels above values
    // Use parsedCharacter if available, fall back to original method
    const race = parsedCharacter?.details?.race || getDisplayValue('race', characterSheet.system?.details?.race);
    const background = parsedCharacter?.details?.background || getDisplayValue('background', characterSheet.system?.details?.background);
    const alignment = parsedCharacter?.details?.alignment || getDisplayValue('alignment', characterSheet.system?.details?.alignment);
    const xp = getNestedValue(characterSheet, 'system.details.xp.value', '0');
    const originalClass = parsedCharacter?.details?.class || getDisplayValue('originalClass', characterSheet.system?.details?.originalClass);
    
    html += `
      <div class="detail-box">
        <div class="detail-label">Race</div>
        <div class="detail-value">${race}</div>
      </div>
      
      <div class="detail-box">
        <div class="detail-label">Background</div>
        <div class="detail-value">${background}</div>
      </div>
      
      <div class="detail-box">
        <div class="detail-label">Alignment</div>
        <div class="detail-value">${alignment}</div>
      </div>
      
      <div class="detail-box">
        <div class="detail-label">XP</div>
        <div class="detail-value">${xp}</div>
      </div>
      
      <div class="detail-box">
        <div class="detail-label">Original Class</div>
        <div class="detail-value">${originalClass}</div>
      </div>
    `;
    
    html += '</div>'; // End character details grid
    html += '</div>'; // End character details section
    
    // Abilities section
    html += '<div class="abilities-section">';
    html += '<h3>ABILITIES</h3>';
    html += '<div class="abilities-container">';
    
    // Use parsed abilities if available, fall back to original
    if (parsedCharacter?.abilities && Object.keys(parsedCharacter.abilities).length > 0) {
      // Use the new parsed ability scores with pre-calculated modifiers
      Object.entries(parsedCharacter.abilities).forEach(([key, ability]) => {
        const sign = ability.mod >= 0 ? '+' : '';
        
        html += `
          <div class="ability-score">
            <div class="ability-name" style="background-color: #2a2a4a; color: #e2e2fd;">${key.toUpperCase()}</div>
            <div class="ability-circle">
              <span class="ability-value">${ability.value}</span>
            </div>
            <div class="ability-modifier">${sign}${ability.mod}</div>
            ${ability.proficient ? '<div class="ability-proficient">Proficient</div>' : ''}
          </div>
        `;
      });
    } else if (characterSheet.system?.abilities) {
      // Fallback to original method
      Object.entries(characterSheet.system.abilities).forEach(([key, ability]) => {
        const mod = getAbilityModifier(ability.value);
        const sign = mod >= 0 ? '+' : '';
        
        html += `
          <div class="ability-score">
            <div class="ability-name" style="background-color: #2a2a4a; color: #e2e2fd;">${key.toUpperCase()}</div>
            <div class="ability-circle">
              <span class="ability-value">${ability.value !== undefined && ability.value !== null ? ability.value : ''}</span>
            </div>
            <div class="ability-modifier">${sign}${mod}</div>
            ${ability.proficient ? '<div class="ability-proficient">Proficient</div>' : ''}
          </div>
        `;
      });
    }
    
    html += '</div>'; // End abilities container
    html += '</div>'; // End abilities section
    
    // Combat Stats Section - EXPANDED with all stats
    html += '<div class="combat-section">';
    html += '<h3>Combat</h3>';
    html += '<div class="combat-stats">';
    
    // HP section - use parsed data if available
    if (parsedCharacter?.attributes?.hp) {
      const hp = parsedCharacter.attributes.hp;
      const hpPercentage = Math.min(100, Math.max(0, ((hp.value || 0) / (hp.max || 1)) * 100));
      
      html += `
        <div class="combat-stat hp-container">
          <div class="stat-name">Hit Points</div>
          <div class="hp-values">
            <span class="current-hp">${hp.value || 0}</span>
            <span class="hp-separator">/</span>
            <span class="max-hp">${hp.max || 0}</span>
          </div>
          <div class="hp-bar">
            <div class="hp-fill" style="width: ${hpPercentage}%"></div>
            <div class="hp-text">${hp.value || 0} / ${hp.max || 0}</div>
          </div>
          ${hp.temp ? `<div class="temp-hp">Temp HP: ${hp.temp}</div>` : ''}
        </div>
      `;
    } else if (characterSheet.system?.attributes?.hp) {
      // Fallback to original
      const hp = characterSheet.system.attributes.hp;
      const hpPercentage = Math.min(100, Math.max(0, ((hp.value || 0) / (hp.max || 1)) * 100));
      
      html += `
        <div class="combat-stat hp-container">
          <div class="stat-name">Hit Points</div>
          <div class="hp-values">
            <span class="current-hp">${hp.value || 0}</span>
            <span class="hp-separator">/</span>
            <span class="max-hp">${hp.max || 0}</span>
          </div>
          <div class="hp-bar">
            <div class="hp-fill" style="width: ${hpPercentage}%"></div>
            <div class="hp-text">${hp.value || 0} / ${hp.max || 0}</div>
          </div>
          ${hp.temp ? `<div class="temp-hp">Temp HP: ${hp.temp}</div>` : ''}
        </div>
      `;
    }
    
    // Core combat stats - use parsed data if available
    const combatStats = [
      { 
        name: 'Armor Class', 
        value: parsedCharacter?.attributes?.ac !== undefined 
          ? parsedCharacter.attributes.ac 
          : (characterSheet.system?.attributes?.ac?.value !== undefined ? characterSheet.system?.attributes?.ac?.value : '') 
      },
      { 
        name: 'Initiative', 
        value: parsedCharacter?.attributes?.initiative !== undefined 
          ? parsedCharacter.attributes.initiative
          : (characterSheet.system?.attributes?.init?.total !== undefined ? characterSheet.system?.attributes?.init?.total : '') 
      },
      { 
        name: 'Speed', 
        value: parsedCharacter?.attributes?.speed !== undefined 
          ? `${parsedCharacter.attributes.speed} ft`
          : (characterSheet.system?.attributes?.movement?.walk !== undefined ? `${characterSheet.system?.attributes?.movement?.walk} ft` : '') 
      },
      { 
        name: 'Proficiency', 
        value: parsedCharacter?.attributes?.profBonus !== undefined 
          ? parsedCharacter.attributes.profBonus
          : (characterSheet.system?.attributes?.prof?.value !== undefined ? characterSheet.system?.attributes?.prof?.value : '') 
      }
    ];
    
    combatStats.forEach(stat => {
      html += `
        <div class="combat-stat">
          <div class="stat-name">${stat.name}</div>
          <div class="stat-value">${stat.value}</div>
        </div>
      `;
    });
    
    // Add other movement types if they exist
    if (characterSheet.system?.attributes?.movement) {
      const movement = characterSheet.system.attributes.movement;
      Object.entries(movement).forEach(([key, value]) => {
        if (key !== 'walk' && value) {
          html += `
            <div class="combat-stat">
              <div class="stat-name">${key.charAt(0).toUpperCase() + key.slice(1)} Speed</div>
              <div class="stat-value">${value} ft</div>
            </div>
          `;
        }
      });
    }
    
    // Add hit dice if they exist
    if (characterSheet.system?.attributes?.hd) {
      const hd = characterSheet.system.attributes.hd;
      html += `
        <div class="combat-stat">
          <div class="stat-name">Hit Dice</div>
          <div class="stat-value">${hd.value || 0}/${hd.max || 0} ${hd.die || 'd8'}</div>
        </div>
      `;
    }
    
    // Add death saves if they exist
    if (characterSheet.system?.attributes?.death) {
      const death = characterSheet.system.attributes.death;
      html += `
        <div class="combat-stat death-saves">
          <div class="stat-name">Death Saves</div>
          <div class="death-save-boxes">
            <div class="success-saves">
              <span>Successes: </span>
              <span class="save-box ${death.success >= 1 ? 'filled' : ''}"></span>
              <span class="save-box ${death.success >= 2 ? 'filled' : ''}"></span>
              <span class="save-box ${death.success >= 3 ? 'filled' : ''}"></span>
            </div>
            <div class="failure-saves">
              <span>Failures: </span>
              <span class="save-box ${death.failure >= 1 ? 'filled' : ''}"></span>
              <span class="save-box ${death.failure >= 2 ? 'filled' : ''}"></span>
              <span class="save-box ${death.failure >= 3 ? 'filled' : ''}"></span>
            </div>
          </div>
        </div>
      `;
    }
    
    html += '</div>'; // End combat stats
    html += '</div>'; // End combat section
    
    // Skills Section - EXPANDED with all skills and details
    html += '<div class="skills-section">';
    html += '<h3>Skills</h3>';
    html += '<div class="skills-container">';
    
    if (characterSheet.system) {
      // Calculate proficiency bonus based on level
      const level = characterSheet.system?.details?.level || 1;
      const proficiencyBonus = Math.floor((level - 1) / 4) + 2;
      
      // Define skills in D&D character sheet order with full names
      const dndSkillOrder = [
        { key: 'acr', name: 'Acrobatics', ability: 'dex' },
        { key: 'ani', name: 'Animal Handling', ability: 'wis' },
        { key: 'arc', name: 'Arcana', ability: 'int' },
        { key: 'ath', name: 'Athletics', ability: 'str' },
        { key: 'dec', name: 'Deception', ability: 'cha' },
        { key: 'his', name: 'History', ability: 'int' },
        { key: 'ins', name: 'Insight', ability: 'wis' },
        { key: 'itm', name: 'Intimidation', ability: 'cha' },
        { key: 'inv', name: 'Investigation', ability: 'int' },
        { key: 'med', name: 'Medicine', ability: 'wis' },
        { key: 'nat', name: 'Nature', ability: 'int' },
        { key: 'prc', name: 'Perception', ability: 'wis' },
        { key: 'prf', name: 'Performance', ability: 'cha' },
        { key: 'per', name: 'Persuasion', ability: 'cha' },
        { key: 'rel', name: 'Religion', ability: 'int' },
        { key: 'slt', name: 'Sleight of Hand', ability: 'dex' },
        { key: 'ste', name: 'Stealth', ability: 'dex' },
        { key: 'sur', name: 'Survival', ability: 'wis' }
      ];
      
      // Render skills in the specified order
      dndSkillOrder.forEach(orderedSkill => {
        // Get the skill data from the character sheet
        const skills = characterSheet.system.skills || {};
        const skill = skills[orderedSkill.key];
        
        // Get the corresponding ability score
        const abilityKey = orderedSkill.ability;
        const abilityScore = characterSheet.system.abilities?.[abilityKey]?.value || 10;
        const abilityMod = getAbilityModifier(abilityScore);
        
        if (skill) {
          // Calculate the skill modifier
          // value: 0 = not proficient, 1 = proficient, 2 = expertise
          const profValue = skill.value || 0;
          const isProficient = profValue > 0;
          const hasExpertise = profValue > 1;
          
          // Calculate the total modifier: ability modifier + (proficiency value × proficiency bonus)
          const totalMod = abilityMod + (profValue * proficiencyBonus);
          const formattedMod = totalMod >= 0 ? `+${totalMod}` : `${totalMod}`;
          
          // Display the ability name in uppercase
          const displayAbility = abilityKey.toUpperCase();
          
          html += `
            <div class="skill-item">
              <div class="skill-proficient ${isProficient ? 'active' : ''}"></div>
              <div class="skill-name">${orderedSkill.name} (${displayAbility})</div>
              <div class="skill-modifier">${formattedMod}</div>
              ${hasExpertise ? '<div class="skill-expertise">Expertise</div>' : ''}
            </div>
          `;
        } else {
          // If skill is not defined, just show the base ability modifier
          const formattedMod = abilityMod >= 0 ? `+${abilityMod}` : `${abilityMod}`;
          const displayAbility = abilityKey.toUpperCase();
          
          html += `
            <div class="skill-item">
              <div class="skill-proficient"></div>
              <div class="skill-name">${orderedSkill.name} (${displayAbility})</div>
              <div class="skill-modifier">${formattedMod}</div>
            </div>
          `;
        }
      });
    } else {
      // If no skills at all, create a placeholder
      html += '<div class="no-items">No skills found</div>';
    }
    
    html += '</div>'; // End skills container
    html += '</div>'; // End skills section
    
    // Features section
    html += '<div class="features-section">';
    html += '<h3>Features & Traits</h3>';
    html += '<div class="features-list">';
    
    // Use parsed features if available
    if (parsedCharacter?.features && parsedCharacter.features.length > 0) {
      // Filter out the specific containers we don't want to show at all
      const filteredFeatures = parsedCharacter.features.filter(feature => {
        const featureName = feature.name || '';
        
        // Skip rendering these containers completely
        if (featureName.includes("Wild Magic Sorcery") || 
            featureName.includes("Way of the Ascendant Dragon") ||
            featureName.includes("Monk") && feature.description && feature.description.includes("Joshua Raphael") ||
            featureName === "Battle Master" ||
            featureName === "BATTLE MASTER" ||
            featureName === "Fighter" ||
            featureName === "FIGHTER" ||
            featureName === "Combat Superiority" ||
            featureName === "Druid" ||
            featureName === "DRUID") {
          return false;
        }
        
        return true;
      });
      
      filteredFeatures.forEach(feature => {
        // Try to determine feature type (class, racial, feat, etc.) from source or name
        let featureType = 'other';
        const featureName = feature.name.toLowerCase();
        const featureSource = feature.source ? feature.source.toLowerCase() : '';
        
        if (featureSource.includes('class') || 
            featureName.includes('ki') || 
            featureName.includes('magic') || 
            featureName.includes('attack') || 
            featureName.includes('martial')) {
          featureType = 'class';
        } else if (featureSource.includes('race') || 
                  featureName.includes('dragon') || 
                  featureName.includes('breath weapon') || 
                  featureName.includes('resistance')) {
          featureType = 'racial';
        } else if (featureSource.includes('feat') || 
                  featureName.includes('alert') || 
                  featureName.includes('lucky') || 
                  featureName.includes('focus')) {
          featureType = 'feat';
        } else if (featureSource.includes('background')) {
          featureType = 'background';
        }
        
        const cleanedDescription = cleanText(feature.description || 'No description available');
        
        html += `
          <div class="feature-item" data-type="${featureType}">
            <div class="feature-name">${cleanText(feature.name)}</div>
            <div class="feature-description">${cleanedDescription}</div>
          </div>
        `;
      });
    } else if (characterSheet.items) {
      // Fallback to original method - extract items that are features and sort them
      const features = characterSheet.items
        .filter(item => item.type === 'feat' || item.type === 'class' || item.type === 'subclass')
        .filter(item => {
          const itemName = item.name || '';
          
          // Skip rendering these containers completely
          if (itemName.includes("Wild Magic Sorcery") || 
              itemName.includes("Way of the Ascendant Dragon") ||
              (itemName.includes("Monk") && item.system?.description?.value && 
               item.system.description.value.includes("Joshua Raphael")) ||
              itemName === "Battle Master" ||
              itemName === "BATTLE MASTER" ||
              itemName === "Fighter" ||
              itemName === "FIGHTER" ||
              itemName === "Combat Superiority" ||
              itemName === "Druid" ||
              itemName === "DRUID") {
            return false;
          }
          
          return true;
        })
        .sort((a, b) => a.name.localeCompare(b.name));
      
      features.forEach(feature => {
        // Try to determine feature type (class, racial, feat, etc.) from type or name
        let featureType = 'other';
        const featureName = feature.name.toLowerCase();
        
        if (feature.type === 'class' || feature.type === 'subclass' || 
            featureName.includes('ki') || 
            featureName.includes('magic') || 
            featureName.includes('attack') || 
            featureName.includes('martial')) {
          featureType = 'class';
        } else if (feature.type === 'race' || 
                  featureName.includes('dragon') || 
                  featureName.includes('breath weapon') || 
                  featureName.includes('resistance')) {
          featureType = 'racial';
        } else if (feature.type === 'feat' || 
                  featureName.includes('alert') || 
                  featureName.includes('lucky') || 
                  featureName.includes('focus')) {
          featureType = 'feat';
        } else if (feature.type === 'background') {
          featureType = 'background';
        }
        
        const cleanedDescription = cleanText(feature.system?.description?.value || 'No description available');
        
        html += `
          <div class="feature-item" data-type="${featureType}">
            <div class="feature-name">${cleanText(feature.name)}</div>
            <div class="feature-description">${cleanedDescription}</div>
          </div>
        `;
      });
    } else {
      html += '<div class="no-items">No features found</div>';
    }
    
    html += '</div>'; // End features list
    html += '</div>'; // End features section
    
    return html;
  };

  // This function will be implemented here
  const renderEquipmentTab = () => {
    if (!characterSheet) return '';
    let html = '';
    
    // Currency section if it exists - use parsed data if available
    if (parsedCharacter?.currency) {
      html += '<div class="currency-section">';
      html += '<h3>Currency</h3>';
      html += '<div class="currency-container">';
      
      const currencyTypes = [
        { key: 'pp', name: 'Platinum' },
        { key: 'gp', name: 'Gold' },
        { key: 'ep', name: 'Electrum' },
        { key: 'sp', name: 'Silver' },
        { key: 'cp', name: 'Copper' }
      ];
      
      currencyTypes.forEach(type => {
        html += `
          <div class="currency-item">
            <span class="currency-name">${type.name}</span>
            <span class="currency-value">${parsedCharacter.currency[type.key] || 0}</span>
          </div>
        `;
      });
      
      html += '</div>'; // End currency container
      html += '</div>'; // End currency section
    } else if (characterSheet.system?.currency) {
      // Fallback to original method
      html += '<div class="currency-section">';
      html += '<h3>Currency</h3>';
      html += '<div class="currency-container">';
      
      const currency = characterSheet.system.currency;
      const currencyTypes = [
        { key: 'pp', name: 'Platinum' },
        { key: 'gp', name: 'Gold' },
        { key: 'ep', name: 'Electrum' },
        { key: 'sp', name: 'Silver' },
        { key: 'cp', name: 'Copper' }
      ];
      
      currencyTypes.forEach(type => {
        html += `
          <div class="currency-item">
            <span class="currency-name">${type.name}</span>
            <span class="currency-value">${currency[type.key] || 0}</span>
          </div>
        `;
      });
      
      html += '</div>'; // End currency container
      html += '</div>'; // End currency section
    }
    
    // Equipment section - NOW AFTER currency
    html += '<div class="equipment-section">';
    html += '<h3>Equipment</h3>';
    html += '<div class="equipment-list">';
    
    // Use parsed equipment items if available
    if (parsedCharacter?.itemsByType) {
      // Get equipment from parsed items
      const equipmentTypes = ['weapon', 'equipment', 'tool', 'consumable', 'container', 'backpack', 'loot'];
      const equipment = [];
      
      // Collect all equipment items from the different types
      equipmentTypes.forEach(type => {
        if (Array.isArray(parsedCharacter.itemsByType[type])) {
          equipment.push(...parsedCharacter.itemsByType[type]);
        }
      });
      
      if (equipment.length === 0) {
        html += '<div class="no-items">No equipment found</div>';
      } else {
        // Sort equipment by type and name
        equipment
          .sort((a, b) => {
            // Ensure type and name are strings
            const typeA = String(a.type || '');
            const typeB = String(b.type || '');
            const nameA = String(a.name || '');
            const nameB = String(b.name || '');
            
            if (typeA === typeB) return nameA.localeCompare(nameB);
            return typeA.localeCompare(typeB);
          })
          .forEach(item => {
            // Render equipment item using the same format as the original code
            const itemType = item.type ? String(item.type).charAt(0).toUpperCase() + String(item.type).slice(1) : 'Item';
            const quantity = item.system?.quantity ? ` (×${item.system.quantity})` : '';
            const weight = item.system?.weight ? ` - ${item.system.weight} lbs` : '';
            
            html += `
              <div class="equipment-item">
                <div class="equipment-name">${item.name || 'Unknown Item'}${quantity}</div>
                <div class="equipment-type">${itemType}${weight}</div>
                <div class="equipment-description">
                  ${item.system?.description?.value 
                    ? `<div>${cleanText(item.system.description.value)}</div>`
                    : '<p>No description available</p>'
                  }
                </div>
            `;
            
            // Add weapon-specific properties
            if (item.type === 'weapon' && item.system) {
              const weapon = item.system;
              html += '<div class="weapon-properties">';
              
              if (weapon.damage) {
                html += `<div class="weapon-damage">Damage: ${weapon.damage.parts?.[0]?.[0] || ''} ${weapon.damage.parts?.[0]?.[1] || ''}</div>`;
              }
              
              if (weapon.attackBonus) {
                html += `<div class="weapon-attack">Attack Bonus: ${weapon.attackBonus}</div>`;
              }
              
              if (weapon.properties) {
                const props = Object.entries(weapon.properties)
                  .filter(([, value]) => value === true)
                  .map(([key]) => key ? key.charAt(0).toUpperCase() + key.slice(1) : '')
                  .join(', ');
                  
                if (props) {
                  html += `<div class="weapon-props">Properties: ${props}</div>`;
                }
              }
              
              if (weapon.range) {
                html += `<div class="weapon-range">Range: ${weapon.range.value || 0}/${weapon.range.long || 0} ft</div>`;
              }
              
              html += '</div>'; // End weapon properties
            }
            
            html += '</div>'; // End equipment item
          });
      }
    } else if (characterSheet.items) {
      // Fallback to original implementation
      // Group equipment by type
      const equipment = characterSheet.items.filter(item => 
        ['weapon', 'equipment', 'tool', 'consumable', 'container', 'backpack', 'loot'].includes(item.type)
      );
      
      if (equipment.length === 0) {
        html += '<div class="no-items">No equipment found</div>';
      } else {
        // Sort equipment by type and name
        equipment
          .sort((a, b) => {
            // Ensure type and name are strings
            const typeA = String(a.type || '');
            const typeB = String(b.type || '');
            const nameA = String(a.name || '');
            const nameB = String(b.name || '');
            
            if (typeA === typeB) return nameA.localeCompare(nameB);
            return typeA.localeCompare(typeB);
          })
          .forEach(item => {
            const itemType = item.type ? String(item.type).charAt(0).toUpperCase() + String(item.type).slice(1) : 'Item';
            const quantity = item.system?.quantity ? ` (×${item.system.quantity})` : '';
            const weight = item.system?.weight ? ` - ${item.system.weight} lbs` : '';
            
            html += `
              <div class="equipment-item">
                <div class="equipment-name">${item.name || 'Unknown Item'}${quantity}</div>
                <div class="equipment-type">${itemType}${weight}</div>
                <div class="equipment-description">
                  ${item.system?.description?.value 
                    ? `<div>${cleanText(item.system.description.value)}</div>`
                    : '<p>No description available</p>'
                  }
                </div>
            `;
            
            // Add weapon-specific properties
            if (item.type === 'weapon' && item.system) {
              const weapon = item.system;
              html += '<div class="weapon-properties">';
              
              if (weapon.damage) {
                html += `<div class="weapon-damage">Damage: ${weapon.damage.parts?.[0]?.[0] || ''} ${weapon.damage.parts?.[0]?.[1] || ''}</div>`;
              }
              
              if (weapon.attackBonus) {
                html += `<div class="weapon-attack">Attack Bonus: ${weapon.attackBonus}</div>`;
              }
              
              if (weapon.properties) {
                const props = Object.entries(weapon.properties)
                  .filter(([, value]) => value === true)
                  .map(([key]) => key ? key.charAt(0).toUpperCase() + key.slice(1) : '')
                  .join(', ');
                  
                if (props) {
                  html += `<div class="weapon-props">Properties: ${props}</div>`;
                }
              }
              
              if (weapon.range) {
                html += `<div class="weapon-range">Range: ${weapon.range.value || 0}/${weapon.range.long || 0} ft</div>`;
              }
              
              html += '</div>'; // End weapon properties
            }
            
            html += '</div>'; // End equipment item
          });
      }
    } else {
      html += '<div class="no-items">No equipment found</div>';
    }
    
    html += '</div>'; // End equipment list
    html += '</div>'; // End equipment section
    
    return html;
  };

  // This function will be implemented here
  const renderSpellsTab = () => {
    if (!characterSheet) return '';
    let html = '';
    
    // Spells section
    html += '<div class="spells-section">';
    html += '<h3>Spells</h3>';
    
    // Spellcasting Ability
    if (characterSheet.system?.attributes?.spellcasting) {
      const spellAbility = characterSheet.system.attributes.spellcasting.ability;
      if (spellAbility) {
        html += `<div class="spellcasting-ability">Spellcasting Ability: ${spellAbility.toUpperCase()}</div>`;
      }
    }
    
    // Spell Slots
    if (characterSheet.system?.spells) {
      html += '<div class="spell-slots-container">';
      
      // Display spell slots by level
      for (let level = 1; level <= 9; level++) {
        const slotKey = `spell${level}`;
        if (characterSheet.system.spells[slotKey]) {
          const slots = characterSheet.system.spells[slotKey];
          html += `
            <div class="spell-slot-level">
              <div class="slot-level">Level ${level}</div>
              <div class="slot-values">
                <span class="slots-used">${slots.value || 0}</span>
                <span class="slots-separator">/</span>
                <span class="slots-total">${slots.max || 0}</span>
              </div>
            </div>
          `;
        }
      }
      
      html += '</div>'; // End spell slots container
    }
    
    // Group spells by level
    html += '<div class="spell-list-by-level">';
    
    // Initialize an array to hold spells by level
    const spellsByLevel = {};
    for (let i = 0; i <= 9; i++) {
      spellsByLevel[i] = [];
    }
    
    // Sort spells into level buckets
    
    // Check for spells in different possible locations
    if (Array.isArray(characterSheet.items)) {
      const spellItems = characterSheet.items.filter(item => 
        item.type === 'spell' || 
        item.type === 'power' || // Some systems use "power" instead of "spell"
        (item.flags && item.flags.spellbook) // Check for spellbook flags
      );
      
      spellItems.forEach(spell => {
        // Try to get level from different possible locations
        const level = spell.system?.level || 
                      spell.level || 
                      spell.data?.level || 
                      (spell.flags?.spellbook?.level) || 
                      0;
        spellsByLevel[level].push(spell);
      });
    } else {
      // Add a placeholder message for no spells
      html += '<div class="no-items">No spells found or items data is invalid</div>';
    }
    
    // Create a section for each level that has spells
    for (let level = 0; level <= 9; level++) {
      if (spellsByLevel[level].length > 0) {
        const levelName = level === 0 ? 'Cantrips' : `Level ${level} Spells`;
        
        html += `<div class="spell-level-group">`;
        html += `<h4 class="spell-level-header">${levelName}</h4>`;
        
        // Sort spells alphabetically within each level
        spellsByLevel[level]
          .sort((a, b) => String(a.name || '').localeCompare(String(b.name || '')))
          .forEach(spell => {
            // Get additional spell details
            const prepared = spell.system?.preparation?.prepared ? ' (Prepared)' : '';
            const school = spell.system?.school ? ` - ${spell.system.school}` : '';
            const castTime = spell.system?.activation?.type ? ` - ${spell.system.activation.type}` : '';
            
            html += `
              <div class="spell-item">
                <div class="spell-name">${spell.name || 'Unknown Spell'}${prepared}</div>
                <div class="spell-meta">${castTime}${school}</div>
                <div class="spell-description">
                  ${spell.system?.description?.value 
                    ? `<div>${cleanText(spell.system.description.value)}</div>`
                    : '<p>No description available</p>'
                  }
                </div>
              </div>
            `;
          });
        
        html += `</div>`; // End spell level group
      }
    }
    
    html += '</div>'; // End spell list by level
    html += '</div>'; // End spells section
    
    return html;
  };

  // This function will be implemented here
  const renderBiographyTab = () => {
    if (!characterSheet) return '';
    let html = '';
    
    // Biography section
    html += '<div class="biography-section">';
    html += '<h3>Biography</h3>';
    
    // Biography section - check multiple possible locations
    
    // Check multiple possible locations for biography data
    let bio = null;
    if (characterSheet.system?.details?.biography) {
      bio = characterSheet.system.details.biography;
    } else if (characterSheet.system?.description) {
      bio = characterSheet.system.description;
    } else if (characterSheet.system?.details?.description) {
      bio = characterSheet.system.details.description;
    } else if (characterSheet.description) {
      bio = characterSheet.description;
    }
    
    if (bio) {
      // Check for bio.value or alternative fields
      if (bio.value || bio.text || bio.public) {
        const bioText = bio.value || bio.text || bio.public || '';
        html += `
          <div class="biography-entry">
            <h4>Biography</h4>
            <div class="biography-text">${cleanText(bioText)}</div>
          </div>
        `;
      }
      
      // Check for appearance field
      if (bio.appearance) {
        html += `
          <div class="biography-entry">
            <h4>Appearance</h4>
            <div class="biography-text">${cleanText(bio.appearance)}</div>
          </div>
        `;
      } else if (bio.physical) { // Alternative field name
        html += `
          <div class="biography-entry">
            <h4>Appearance</h4>
            <div class="biography-text">${cleanText(bio.physical)}</div>
          </div>
        `;
      }
      
      // Check for trait field
      if (bio.trait) {
        html += `
          <div class="biography-entry">
            <h4>Personality Traits</h4>
            <div class="biography-text">${cleanText(bio.trait)}</div>
          </div>
        `;
      } else if (bio.traits) { // Alternative field name
        html += `
          <div class="biography-entry">
            <h4>Personality Traits</h4>
            <div class="biography-text">${cleanText(bio.traits)}</div>
          </div>
        `;
      } else if (bio.personality) { // Alternative field name
        html += `
          <div class="biography-entry">
            <h4>Personality Traits</h4>
            <div class="biography-text">${cleanText(bio.personality)}</div>
          </div>
        `;
      }
      
      // Check for ideal field
      if (bio.ideal) {
        html += `
          <div class="biography-entry">
            <h4>Ideals</h4>
            <div class="biography-text">${cleanText(bio.ideal)}</div>
          </div>
        `;
      } else if (bio.ideals) { // Alternative field name
        html += `
          <div class="biography-entry">
            <h4>Ideals</h4>
            <div class="biography-text">${cleanText(bio.ideals)}</div>
          </div>
        `;
      }
      
      // Check for bond field
      if (bio.bond) {
        html += `
          <div class="biography-entry">
            <h4>Bonds</h4>
            <div class="biography-text">${cleanText(bio.bond)}</div>
          </div>
        `;
      } else if (bio.bonds) { // Alternative field name
        html += `
          <div class="biography-entry">
            <h4>Bonds</h4>
            <div class="biography-text">${cleanText(bio.bonds)}</div>
          </div>
        `;
      }
      
      // Check for flaw field
      if (bio.flaw) {
        html += `
          <div class="biography-entry">
            <h4>Flaws</h4>
            <div class="biography-text">${cleanText(bio.flaw)}</div>
          </div>
        `;
      } else if (bio.flaws) { // Alternative field name
        html += `
          <div class="biography-entry">
            <h4>Flaws</h4>
            <div class="biography-text">${cleanText(bio.flaws)}</div>
          </div>
        `;
      }
      
      // Check for backstory field
      if (bio.backstory) {
        html += `
          <div class="biography-entry">
            <h4>Backstory</h4>
            <div class="biography-text">${cleanText(bio.backstory)}</div>
          </div>
        `;
      } else if (bio.background) { // Alternative field name
        html += `
          <div class="biography-entry">
            <h4>Backstory</h4>
            <div class="biography-text">${cleanText(bio.background)}</div>
          </div>
        `;
      }
    } else {
      html += '<div class="no-items">No biography information found</div>';
    }
    
    html += '</div>'; // End biography section
    return html;
  };

  return (
    <div className="character-tab">
      {isLoadingCharacter ? (
        <div className="loading-character">Loading character sheet...</div>
      ) : characterError ? (
        <div className="character-error">{characterError}</div>
      ) : !characterSheet ? (
        <div className="no-character-sheet">
          <h4>No Character Sheet Found</h4>
          <p>Upload your character sheet to see it displayed here.</p>
          
          {/* Character sheet tabs - even when there's no sheet */}
          <div className="character-sheet-tabs">
            <button 
              className={`character-tab-button ${characterTabActive === 'details' ? 'active' : ''}`} 
              onClick={() => handleCharacterTabClick('details')}
              data-tab="details"
            >
              Details & Abilities
            </button>
            <button 
              className={`character-tab-button ${characterTabActive === 'equipment' ? 'active' : ''}`} 
              onClick={() => handleCharacterTabClick('equipment')}
              data-tab="equipment"
            >
              Equipment & Currency
            </button>
            <button 
              className={`character-tab-button ${characterTabActive === 'spells' ? 'active' : ''}`} 
              onClick={() => handleCharacterTabClick('spells')}
              data-tab="spells"
            >
              Spells
            </button>
            <button 
              className={`character-tab-button ${characterTabActive === 'biography' ? 'active' : ''}`} 
              onClick={() => handleCharacterTabClick('biography')}
              data-tab="biography"
            >
              Biography
            </button>
          </div>
          
          {/* Tab content container */}
          <div className="character-sheet-tab-content">
            {/* Tab 1: Details */}
            <div className={`character-tab-pane ${characterTabActive === 'details' ? 'active' : ''}`} id="details-tab">
              <div className="no-items">No character details available. Upload a character sheet to see information here.</div>
            </div>
            
            {/* Tab 2: Equipment */}
            <div className={`character-tab-pane ${characterTabActive === 'equipment' ? 'active' : ''}`} id="equipment-tab">
              <div className="no-items">No equipment data available. Upload a character sheet to see information here.</div>
            </div>
            
            {/* Tab 3: Spells */}
            <div className={`character-tab-pane ${characterTabActive === 'spells' ? 'active' : ''}`} id="spells-tab">
              <div className="no-items">No spell data available. Upload a character sheet to see information here.</div>
            </div>
            
            {/* Tab 4: Biography */}
            <div className={`character-tab-pane ${characterTabActive === 'biography' ? 'active' : ''}`} id="biography-tab">
              <div className="no-items">No biography information available. Upload a character sheet to see information here.</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="character-sheet-content">
          {/* Character content header */}
          <div className="character-header">
            <h2 className="character-name">{characterSheet.name || 'Unnamed Character'}</h2>
            <div className="character-portrait">
              {avatarUrl ? (
                <img src={avatarUrl} alt={characterSheet.name || 'Character'} />
              ) : (
                <div className="character-portrait-placeholder">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="64" height="64">
                    <path fill="#8B0000" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z" />
                  </svg>
                </div>
              )}
            </div>
            {characterPdfUrl && (
              <div className="character-pdf-download">
                <a 
                  href={`${characterPdfUrl}&token=${encodeURIComponent(localStorage.getItem('auth_token'))}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pdf-download-button"
                >
                  Download PDF
                </a>
              </div>
            )}
          </div>
          
          {/* Character sheet tabs */}
          <div className="character-sheet-tabs">
            <button 
              className={`character-tab-button ${characterTabActive === 'details' ? 'active' : ''}`} 
              onClick={() => handleCharacterTabClick('details')}
              data-tab="details"
            >
              Details & Abilities
            </button>
            <button 
              className={`character-tab-button ${characterTabActive === 'equipment' ? 'active' : ''}`} 
              onClick={() => handleCharacterTabClick('equipment')}
              data-tab="equipment"
            >
              Equipment & Currency
            </button>
            <button 
              className={`character-tab-button ${characterTabActive === 'spells' ? 'active' : ''}`} 
              onClick={() => handleCharacterTabClick('spells')}
              data-tab="spells"
            >
              Spells
            </button>
            <button 
              className={`character-tab-button ${characterTabActive === 'biography' ? 'active' : ''}`} 
              onClick={() => handleCharacterTabClick('biography')}
              data-tab="biography"
            >
              Biography
            </button>
          </div>
          
          {/* Tab content container - only render the active tab */}
          <div className="character-sheet-tab-content">
            {characterTabActive === 'details' && (
              <div className="character-tab-pane active" id="details-tab" 
                   dangerouslySetInnerHTML={{ __html: renderDetailsTab() }} />
            )}
            
            {characterTabActive === 'equipment' && (
              <div className="character-tab-pane active" id="equipment-tab" 
                   dangerouslySetInnerHTML={{ __html: renderEquipmentTab() }} />
            )}
            
            {characterTabActive === 'spells' && (
              <div className="character-tab-pane active" id="spells-tab" 
                   dangerouslySetInnerHTML={{ __html: renderSpellsTab() }} />
            )}
            
            {characterTabActive === 'biography' && (
              <div className="character-tab-pane active" id="biography-tab" 
                   dangerouslySetInnerHTML={{ __html: renderBiographyTab() }} />
            )}
          </div>
          
          <div className="character-sheet-refresh">
            <button 
              className="refresh-button" 
              onClick={refreshCharacterSheet}
              disabled={isLoadingCharacter}
            >
              {isLoadingCharacter ? 'Refreshing...' : 'Refresh Character Sheet'}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Character;
